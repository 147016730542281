import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Parametro } from "../model/Clases";

@Injectable({
  providedIn: 'root'
})
export class ParametrosSvc {

  public server:string;
  public header:HttpHeaders;

  constructor (private http:HttpClient) {
    this.server = environment.URL_SERVICE;
    this.header = new HttpHeaders({
      'Content-Type':'application/json'
    });
  }

  public getAllParams() : Observable<Parametro[]> {
    let url = this.server+'WSParams/getAllParams';
    return this.http.post<Parametro[]>(url, null, {headers: this.header});
  }

  public getIp(): Observable<any> {
    return this.http.get(environment.URL_CONSULTAR_IP);
  }

}
