import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModRequestDatosPersonales } from 'src/app/model/cliente/datosCliente';
import {HttpHeaders, HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FechaTraerInterface } from 'src/app/aplicativo/model/Clases';
import { AutenticacionApi } from '../autenticacionApi';


@Injectable({
  providedIn: 'root'
})
export class ActualizarDatosService {

  public server = environment.URL_SERVICE;
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient,private authApi: AutenticacionApi) { }

  obtenerDatosPersonales(request: ModRequestDatosPersonales) {
    let url = this.server + 'WSSolCredito/obtenerDatosPersonales';
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }
  obtenerUltimaSesion(identidad: string): Observable<FechaTraerInterface> {
    const url = this.server + 'WSSolCredito/getUltimaFecha?xIdentificacion='+btoa(identidad);
    return this.authApi.authAPI().pipe(
      switchMap(response => {
        let headers = new HttpHeaders({
          // 'Accept': '*/*',
          // 'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Methods': 'POST',
            'Content-Type':'text/plain',
          // 'Access-Control-Allow-Headers': '*',
            'Authorization': 'Bearer ' +  response.sToken,
          });

        return this.http.get<FechaTraerInterface>(url,{
          headers
        });
      })
    );



  }

  AndresclienteCignus(){
    let request: {iTipoIdent: '1', sIdent: '41527164'}
    console.log("Andres metodo andres");
    let url = this.server + 'WSClientCygnus/consultarPersonaCygnus';
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }

  AndresclienteCignus2(iTipoIdent:number, sIdent:string) {
    let url = this.server + 'WSClientCygnus/consultarPersonaCygnus';
    let params = new HttpParams();
    params = params.append('iTipoIdent', String(iTipoIdent));
    params = params.append('sIdent', sIdent);
    return this.http.post<any>(url, null, {
      headers: this.headers,
      params: params
    });
  }
}
